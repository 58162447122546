import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { FunctionComponent } from "react"
import Helmet from "react-helmet"

type SEOProps = {
    title: string
    description?: string
    lang?: string
    keywords?: string[]
}

export const SEO: FunctionComponent<SEOProps> = props => {
    let { description, lang, keywords, title } = props
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    )

    lang = lang ?? "en"
    keywords = keywords ?? []
    description = description ?? site.siteMetadata.description

    const metaData: object = [
        {
            name: "description",
            content: description
        },
        {
            property: "og:title",
            content: title
        },
        {
            property: "og:description",
            content: description
        },
        {
            property: "og:type",
            content: "website"
        },
        {
            name: "twitter:card",
            content: "summary"
        },
        {
            name: "twitter:creator",
            content: site.siteMetadata.author
        },
        {
            name: "twitter:title",
            content: title
        },
        {
            name: "twitter:description",
            content: description
        }
    ].concat(keywords.length > 0 ? { name: "keywords", content: keywords.join(", ") } : [])

    return (
        <Helmet
            htmlAttributes={{ lang }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={metaData}
        />
    )
}

export default SEO
